import { request } from "@/utils/request"

export function createOrder(data) {
    return request({
        url: "api/advertising/shopee/create",
        method: "post",
        data
    })
}
export function getListOrder(param = null) {
    return request({
        url: "api/advertising/shopee/list" + (param ? param : ""),
        method: "get"
    })
}

export function removeOrder(data) {
    return request({
        url: "api/advertising/shopee/remove",
        method: "post",
        data
    })
}

export function reCheckId(data) {
    return request({
        url: "/advertising/shopee/recheck-object-exits/" + data.id,
        method: "post",
        data
    })
}
